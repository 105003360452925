<template>
	<div id="pastServicesDetail">
		<div class="opinionBox">
      <div class="opinionTitle">意见</div>
      <div class="opinionText" v-if='opinion'>{{detailData.opinion}}</div>
      <van-field
        v-else
        v-model="detailData.opinion"
        rows="2"
        autosize
        type="textarea"
        maxlength="200"
        placeholder="请输入不少于10个字的描述"
        show-word-limit
        @input="opinionInput"
      />
      
    </div>
    <div class="opinionBox">
      <div class="opinionTitle">上传转账凭证（最多5张，图片大小不超过2MB）</div>
      <van-uploader 
        v-model="imgList"
        multiple 
        :max-count="5"
        :after-read="afterRead"
        @delete='afterDelete'
        @oversize='oversize'
      />
    </div>
    <div class="opinionBox">
      <div class="pastServicesLine">
        <div class="pastServicesDetailText">姓名：</div>
        <div class="pastServicesMassage">{{detailData.name}}</div>
      </div>
      <div class="pastServicesLine">
        <div class="pastServicesDetailText">电话：</div>
        <div class="pastServicesMassage">{{detailData.phone}}</div>
      </div>
      <div class="pastServicesLine">
        <div class="pastServicesDetailText">车务需求：</div>
        <div class="pastServicesMassage">{{detailData.trainDemandVlue}}</div>
      </div>
      <div class="pastServicesLine">
        <div class="pastServicesDetailText">接送车服务：</div>
        <div class="pastServicesMassage">{{detailData.shuttleServiceValue}}</div>
      </div>
      <div class="pastServicesLine" v-if='detailData.shuttleServiceType == 1'>
        <div class="pastServicesDetailText">时间：</div>
        <div class="pastServicesMassage">{{detailData.time}}</div>
      </div>
      <div class="pastServicesLine" v-if='detailData.shuttleServiceType == 1'>
        <div class="pastServicesDetailText">地点：</div>
        <div class="pastServicesMassage">{{detailData.address}}</div>
      </div>
      <div class="pastServicesLine">
        <div class="pastServicesDetailText">备注：</div>
        <div class="pastServicesMassage">{{detailData.remarks}}</div>
      </div>
    </div>
    <div class="footerBox" @click="pastSubmit" >
        <div class="footerBtn" :style="{ background: btnDisable ? '#cccccc' : '#e50012' }">提交</div>
      </div>
	</div>
</template>

<script>
import { validApp, getUrlParam, setCookie, getCookie } from '../../lib/utils';
import { Dialog, Toast } from 'vant';
import { pastServicesDetail, upFile, trafficPastServicesAdd, trafficPastServicesUpdate } from '@/server/request'
let deviceFileArr = [];
export default {
	data() {
		return {
      detailData:{
      },
      imgUrl:[],
      imgList: [],
      opinion: '',
      id: getUrlParam('id'),
      trainDemandVlueList:[],
      isDisable: false,
      btnDisable: false,
      backImg: []
		}
	},
	created() {
		document.title = '过往服务'
		this.getpastServicesDetail()
	},
	mounted() {
		
	},
	computed: {
		
	},
	methods: {
    getpastServicesDetail(){
      let params = {
        data:{
          id: this.id
        }
      }
      pastServicesDetail(params).then(res=>{
        if(res.status=='200'){
          this.detailData = res.data
          if(this.detailData.shuttleServiceType == 0){
            this.detailData.shuttleServiceValue = '否'
          }else{
            this.detailData.shuttleServiceValue = '是'
          }
          this.opinion = res.data.opinion
          if(this.opinion){
            this.btnDisable = true
          }
          for( var i = 0 ; i < this.detailData.trainDemandType.length ; i++ ){
            this.trainDemandVlueList.push(this.detailData.trainDemandType[i].name)
          }
          this.detailData.trainDemandVlue = this.trainDemandVlueList.join('、')
          if(this.detailData.urlList){
            this.imgUrl = this.detailData.urlList.split(',')
            this.backImg = this.imgUrl
            for(var i = 0 ; i < this.imgUrl.length; i++){
              this.imgList.push({
                url: this.showgroupImage(this.imgUrl[i]),
                deletable: false
              })
            }
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    showgroupImage(data) {
      return data + '?Authorization=' + getCookie('CWTOKEN')
    },
    afterRead(file,detail){
      let deviceFile = []//选择的图片数组
      if(Array.isArray(file)){ //因为该组件单选是对象，多选是数组
        deviceFile = file
      }else{
        deviceFile.push(file)
      }
      deviceFile.map((item)=>{
        let formData = new FormData();
      //files是后台参数name字段对应值
        formData.append('files', item.file ,item.file.name);
        item.status = 'uploading';
        item.message = '上传中...';
        item.isDisable = true;
        upFile(formData).then(res => {//将formDate文件上传，会返回图片地址
          let url = [];
          if(res.status==200){
            this.btnDisable = false
            for(let i in res.data){
              this.imgUrl=[...this.imgUrl,res.data[i]];
            }
            item.status = 'success';
            item.message = '上传成功';
            item.isDisable = false;
          }
        });
      })
      deviceFileArr= deviceFileArr.concat(deviceFile);
    },
    afterDelete(file,detail){
      this.imgUrl.splice( detail.index, 1 )
      if(this.backImg.length == this.imgUrl.length){
        this.btnDisable = true
      }
    },
    pastSubmit(){
      if(this.btnDisable){
        return
      }
      var everyResult = deviceFileArr.every( item => item.status == 'success' );
      if(!this.detailData.opinion){
        Toast('请填写意见')
      }else if(this.detailData.opinion.length < 10){
        Toast('意见不能小于10个字')
      }else if(!everyResult){
        Toast('图片正在上传，请稍后')
      }else{
        deviceFileArr = [];
        let params = {
          data:{
            id: this.id,
            opinion: this.detailData.opinion,
            imgUrl: this.imgUrl.join(',')
          }
        }
        Dialog.confirm({
          title: '提示',
          message: '提交后无法修改，请确认是否提交',
        }).then(() => {
          if(this.opinion){
            trafficPastServicesUpdate(params).then(res=>{
              if(res.status=='200'){
              this.$router.go(-1)
              }else{
                Toast.fail({
                  message: res.msg,
                  duration:'3000'
                });
              }
            })
          }else{
            trafficPastServicesAdd(params).then(res=>{
              if(res.status=='200'){
              this.$router.go(-1)
              }else{
                Toast.fail({
                  message: res.msg,
                  duration:'3000'
                });
              }
            })
          }
        })
      }
    },
    oversize(){
      Toast('图片大小不得超过2MB')
    },
    opinionInput(str){
      this.detailData.opinion = str.replace(/ /g,'')
    }
  },
};
</script>

<style>
html,body{
  height: 100%;
}
#app{
  height: 100%;
}
#pastServicesDetail{
  background-color: #F5F5F5;
  padding-top: 0.3rem;
  padding-bottom: 1.4rem;
}
.opinionBox{
  background-color: #ffffff;
  padding: 0.3rem 0.4rem;
  margin-bottom: 0.3rem;
}
.opinionTitle{
  font-size: 0.3rem;
  color: #333333;
  margin-bottom: 0.2rem;
}
.opinionText{
  color: #333333;
  font-size: 0.3rem;
}
.van-field{
  border: 1px solid #EEEEEE;
}
.van-uploader__preview{
  margin-right: 0.4rem;
  margin-bottom: 0.5rem;
}
.van-uploader__preview:nth-child(4n),.van-uploader__upload:nth-child(4n){
   margin-right: 0;
}
.van-uploader__preview-image,.van-uploader__file,.van-uploader__upload{
  width: 1.36rem;
  height: 1.36rem;
}
.pastServicesOne{
  padding: 0.1rem 0.4rem 0.3rem;
  background-color: #ffffff;
  border-radius: 0.3rem;
}
.pastServicesLine{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 0.36rem;
  padding: 0.15rem 0;
}
.pastServicesDetailText{
  width: 2rem;
  color: #333333;
  font-size: 0.3rem;
}
.pastServicesMassage{
  color: #666666;
  font-size: 0.3rem;
  width: 4.04rem;
}
.footerBox{
  background-color: #ffffff;
  padding: 0.2rem 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
	position: fixed;
	bottom: 0;
}
.footerBtn {
  width: 6.7rem;
  height: 0.9rem;
  border-radius: 0.9rem;
  text-align: center;
  line-height: 0.9rem;
  background-color: #e50012;
  color: #ffffff;
}
</style>
